<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <!-- <el-form-item>
          <el-input v-model="search.set_meal_code" @change="Search" placeholder="套餐编号"></el-input>
        </el-form-item> -->
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="addcontent">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <!-- <el-table-column label="套餐编号">
        <template v-slot="scope">
          <span>{{ scope.row.set_meal_code }}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="最小数量">
        <template v-slot="scope">
          <span>{{ scope.row.min_num }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最大数量">
        <template v-slot="scope">
          <span>{{ scope.row.max_num }}</span>
        </template>
      </el-table-column>
      <el-table-column label="折扣率">
        <template v-slot="scope">
          <span>{{ scope.row.discount_rate }}</span>
        </template>
      </el-table-column>
      <el-table-column label="启用状态">
        <template v-slot="scope">
          <span>{{ scope.row.status==0?'未启用':'启用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="editSale(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="deleteSale(scope.row.code)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'套餐折扣'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <!-- <el-form-item label="套餐编号" prop="set_meal_code" label-width="110px">
          <el-input v-model="form.set_meal_code" autocomplete="off"></el-input>
        </el-form-item> -->
        <el-form-item label="最小数量" prop="min_num" label-width="110px">
          <el-input-number
            v-model="form.min_num"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="最大数量" prop="max_num" label-width="110px">
          <el-input-number
            v-model="form.max_num"
            :min="1"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="折扣率" prop="discount_rate" label-width="110px">
          <el-input-number
            v-model="form.discount_rate"
            :min="0"
            :max="100"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="启用状态" prop="status" label-width="110px">
          <el-select v-model="form.status" placeholder="请选择">
            <el-option label="未启用" :value="0"></el-option>
            <el-option label="启用" :value="1"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import api from '../../../axios/pack'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    var checkDiscount = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('折扣率不能为空'))
      }
      let reg = /^([1-9]\d*|0)(\.\d{1,2})?$/
      if (!reg.test(value)) {
        callback(new Error('请输入整数或最多两位小数的数值型'))
      } else {
        callback()
      }
    }
    return {
      search: {
        page: 1,
        limit: 10,
        set_meal_code: ''
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      edit: false,
      form: {
        code: '',
        set_meal_code: '',
        min_num: '',
        max_num: '',
        discount_rate: '',
        status: '',
      },
      rules: {
        // set_meal_code: [{ required: true, message: '请输入套餐编号', trigger: 'blur' }],
        min_num: [{ required: true, message: '请输入最小数量', trigger: 'change' }],
        max_num: [{ required: true, message: '请输入最大数量', trigger: 'change' }],
        discount_rate: [{ required: true, validator: checkDiscount, trigger: 'blur' }],
        status: [{ required: true, message: '请输入启用状态', trigger: 'blur' }],
      },
      promodel: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search(){
      api.PackSaleList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    addcontent () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {}
      this.edit = false
      this.title = '新增'
      this.dialogFormVisible = true
    },
    editSale (item) {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.edit = true
      this.title = '修改'
      this.dialogFormVisible = true
      api.PackSaleDetail({code:item.code}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          if (this.form.min_num > this.form.max_num) {
            this.$message.error('最小数量不能大于最大数量!')
            return
          }
          call()
        }
      })
    },
    addconfirm(){
      this.update()
    },
    update(){
      api.PackSaleUpdate({
        code: this.form.code,
        set_meal_code: this.search.set_meal_code,
        min_num: +this.form.min_num,
        max_num: +this.form.max_num,
        discount_rate: +this.form.discount_rate,
        status: this.form.status,
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          this.dialogFormVisible = false
          this.Search()
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    editconfirm(){
      this.update()
    },
    deleteSale(item) {
      this.$confirm('是否删除该套餐折扣?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        api.PackSaleDelete({code: item}).then(res => {
           if (res.data.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.Search()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    goback () {
      this.$router.push('/iot/pack')
    }
  },
  filters: {},
  mounted () {
    this.search.set_meal_code = this.$route.params.code
    this.Search()
  },
  created () {
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
